import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';

@Pipe({ name: 'filterCollection' })
export class FilterCollectionPipe implements PipeTransform {
  transform(collection: any[], searchText: string, searchableFields: string[]) {
    if (!searchText) {
      return collection;
    }

    return collection.filter((item) =>
      searchableFields.some((field) =>
        (String(_.get(item, field)) ?? '').toLowerCase().includes(searchText.toLowerCase()),
      ),
    );
  }
}
