import { NgModule } from '@angular/core';

import { FilterCollectionPipe } from './filter-collection.pipe';
import { FirstNamePipe } from './first-name.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';

@NgModule({
  declarations: [FilterCollectionPipe, FirstNamePipe, SafeHtmlPipe],
  exports: [FilterCollectionPipe, FirstNamePipe, SafeHtmlPipe],
})
export class SharedPipesModule {}
